<template>
    <div class="d-flex justify-content-center">
        <form @submit.prevent="submit" class="p-3" style="max-width: 450px;">
            <div class="row g-3">
                <div class="col-md-6 mb-3">
                    <label for="firstName" class="form-label">Jméno</label>
                    <input v-model="firstName" type="text" class="form-control" id="firstName" placeholder="Jméno">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="lastName" class="form-label">Příjmení</label>
                    <input v-model="lastName" type="text" class="form-control" id="lastName" placeholder="Příjmení">
                </div>
                <div class="col-12 mb-3">
                    <label for="username" class="form-label">Uživatelské jméno</label>
                    <input v-model="username" type="text" class="form-control" id="username" placeholder="Uživatelské jméno" required>
                </div>
                <div class="col-12 mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input v-model="email" type="email" class="form-control" id="email" placeholder="Email" required>
                </div>
                <div class="col-12 mb-3">
                    <label for="phone" class="form-label">Telefon</label>
                    <input v-model="phone" type="tel" class="form-control" id="phone" placeholder="Telefon" required>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="password" class="form-label">Heslo</label>
                    <input v-model="password" type="password" class="form-control" id="password" placeholder="Heslo">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="passwordConfirmation" class="form-label">Potvrdit heslo</label>
                    <input v-model="passwordConfirmation" type="password" class="form-control" id="passwordConfirmation" placeholder="Potvrdit heslo">
                </div>
                <div class="col-12">
                    <div id="recaptcha"></div>
                </div>
                <div class="col-12 d-grid">
                    <button type="submit" class="btn btn-primary">Registrovat se</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
/* global grecaptcha */
export default {
    data() {
        return {
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            phone: '',
            password: '',
            passwordConfirmation: '',
            recaptcha: '',
        };
    },
    mounted() {
        grecaptcha.ready(() => {
            grecaptcha.execute('6LeAhQgmAAAAAGM6elf-rf_PGsmS79g_CzCZQvRX', {action: 'submit'}).then((token) => {
                this.recaptcha = token;
            });
        });
    },
    methods: {
        submit() {
            // Zde implementujte logiku pro odeslání dat
        },
    },
};
</script>
