<template>
    <div class="d-flex justify-content-center">
        <form @submit.prevent="submit" class="p-3" style="max-width: 450px;">
            <div class="row">
                <div class="mb-3">
                    <label for="username" class="form-label">Uživatelské jméno</label>
                    <input v-model="username" type="text" class="form-control" id="username" placeholder="Uživatelské jméno" required>
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label">Heslo</label>
                    <input v-model="password" type="password" class="form-control" id="password" placeholder="Heslo" required>
                </div>
            </div>
            <div class="d-grid">
                <button type="submit" class="btn btn-primary">Přihlásit se</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
        };
    },
    methods: {
        submit() {
            // Zde implementujte logiku pro odeslání dat
        },
    },
};
</script>
<style>
container {

}
</style>
