import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from '@/App.vue'
import LoginForm from '@/components/LoginForm.vue'
import RegisterForm from '@/components/RegisterForm.vue'
import ForgotPassword from '@/components/ForgotPassword.vue'

// Definice cest
const routes = [
    { path: '/login', component: LoginForm },
    { path: '/register', component: RegisterForm },
    { path: '/forgot-password', component: ForgotPassword },
    // další cesty můžete přidat zde
]

// Vytvoření routeru
const router = createRouter({
    history: createWebHistory(),
    routes,
})

// Vytvoření Vue aplikace
const app = createApp(App)

// Použití routeru
app.use(router)

// Mount aplikace
app.mount('#app')
