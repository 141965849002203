<template>
    <div class="d-flex justify-content-center">
        <form @submit.prevent="submit" class="p-3" style="max-width: 450px;">
            <div class="row g-3">
                <div class="col-12">
                    <label for="email" class="form-label">Email</label>
                    <input v-model="email" type="email" class="form-control" id="email" placeholder="Email" required>
                </div>
                <div class="col-12 d-grid">
                    <button type="submit" class="btn btn-primary">Obnovit heslo</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
        };
    },
    methods: {
        submit() {
            // Zde implementujte logiku pro odeslání dat
        },
    },
};
</script>
