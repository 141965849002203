<!-- src/components/NavbarPage.vue -->
<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">Název aplikace</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Přihlášení
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><router-link class="dropdown-item" to="/login">Přihlásit se</router-link></li>
                            <li><router-link class="dropdown-item" to="/register">Registrovat se</router-link></li>
                            <li><router-link class="dropdown-item" to="/forgot-password">Zapomenuté heslo</router-link></li>
                        </ul>
                    </li>
                    <li><router-link class="nav-link" to="/contact">Kontakt</router-link></li>
                    <!-- Další položky menu zde -->
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavbarPage',
}
</script>

<style scoped>
/* Přidejte zde vlastní styly pro navigační menu */
</style>
/style>