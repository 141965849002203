<template>
    <div id="app">
        <navbar-page></navbar-page>
        <router-view></router-view> <!-- Komponenta pro zobrazování obsahu závislého na cestě -->
    </div>
</template>

<script>
import NavbarPage from '@/components/NavbarPage.vue'

export default {
    name: 'App',
    components: {
        NavbarPage
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
